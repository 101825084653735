import { Box, Typography, Button } from '@mui/material';
import { useState } from 'react';
import { flexCentered } from '@styles/shared/SCss';
import CloseIcon from '@mui/icons-material/Close';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useFormik } from 'formik';
import useProject from '@hooks/useProject';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import { projectEditValuesInitialValues } from '@formik/project/ProjectInitialData';
import { useMoralisFile } from 'react-moralis';
import {
  CardTitle,
  ProjectDetailsCard,
} from '@styles/project/SProjectSinglePage';
import { ProjectCreateDetails, CloseBtn } from '@styles/project/SProjectCreate';
import { EditBtnStyle } from '@styles/project/SProjectEdit';
import { ProjectEditValuesValidationSchema } from '@formik/project/ProjectValidation';
import { FieldStyled } from '@styles/modal/SModal';
import { ProjectStatus } from '@constants/CProject';
import { TProject } from '@typescript/models/Project.model';
import useNotification from '@hooks/useNotification';

interface Props {
  project: TProject;
  onClose: () => void;
}

function ProjectEditValues({ project, onClose }: Props) {
  const { notifyError, notifySuccess } = useNotification();

  const { updateProject } = useProject();

  const { saveFile } = useMoralisFile();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const initialValues = projectEditValuesInitialValues(project);

  const formik = useFormik({
    initialValues,
    validationSchema: ProjectEditValuesValidationSchema,
    onSubmit: () => {},
  });

  const {
    values,
    handleBlur,
    handleChange,
    errors,

    setValues,
    setTouched,
    submitForm,
  } = formik;

  // const handleIsHidden = (state: boolean) => {
  //   setValues((prev) => ({ ...prev, hidden: state }));
  // };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const {
        tokenPrice,
        minInvestment,
        maxInvestment,
        fundraisingTarget,
        feeInPercentage,
        feeInPercentagePrivate,
      } = values;

      await updateProject({
        ...values,
        id: project.id,
        fundraisingTarget:
          +fundraisingTarget || +initialValues.fundraisingTarget,
        tokenPrice: +tokenPrice || +initialValues.tokenPrice,
        minInvestment: +minInvestment || +initialValues.minInvestment,
        maxInvestment: +maxInvestment || +initialValues.maxInvestment,
        feeInPercentage: feeInPercentage || +initialValues.feeInPercentage,
        feeInPercentagePrivate:
          feeInPercentagePrivate || +initialValues.feeInPercentagePrivate,
      });
      notifySuccess('Project Data updated!');
    } catch (error) {
      notifyError('Project Data update failed!');
      throw error;
    } finally {
      submitForm();
      setIsLoading(false);
      onClose();
    }
  };

  const handleProjectStatus = (status: ProjectStatus) => {
    setValues((prev) => ({ ...prev, status }));
  };

  const handleAddNetworkImage = async (event: HTMLInputElement) => {
    const file = event.files?.[0];

    if (file) {
      const fileName = file.name;
      const fileIpfs = await saveFile(fileName, file, { saveIPFS: true });
      if (fileIpfs) {
        setValues((prev) => ({ ...prev, networkImage: fileIpfs._url }));
      }
    }
  };

  const removeNetworkImage = () => {
    setValues((prev) => ({ ...prev, networkImage: '' }));
    setTouched({ networkImage: true });
  };

  if (isLoading) return <IsLoadingCom dimension="medium" />;
  return (
    <>
      <Box
        sx={{
          pt: 2,
          px: 2,
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="outlined"
          disabled={Object.keys(errors).length !== 0}
          sx={{
            display: 'flex',
            width: { xs: '125px', xsm: '175px' },
            px: { xs: 0.5, xsm: 2 },
            py: '19px',
            mr: 2,
          }}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button onClick={onClose} sx={{ ...EditBtnStyle }}>
          <CloseIcon sx={{ width: 20, height: 20 }} />
        </Button>
      </Box>
      <ProjectCreateDetails sx={{ pt: 2 }}>
        <ProjectDetailsCard sx={{ gridArea: 'div1', height: '270px' }}>
          <CardTitle sx={{ marginBottom: '1.3rem' }}>Project Fee</CardTitle>
          <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', mt: 4 }}>
            <FieldStyled
              id="feeInPercentage"
              name="feeInPercentage"
              label="Fee in Percentage"
              sx={{ minHeight: '90px' }}
              value={values.feeInPercentage}
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              helperText={errors.feeInPercentage}
              error={Boolean(errors.feeInPercentage)}
            />
            <FieldStyled
              id="feeInPercentagePrivate"
              name="feeInPercentagePrivate"
              label="Fee in Percentage Private"
              sx={{ minHeight: '90px' }}
              value={values.feeInPercentagePrivate}
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              helperText={errors.feeInPercentagePrivate}
              error={Boolean(errors.feeInPercentagePrivate)}
            />
          </Box>
        </ProjectDetailsCard>
        <ProjectDetailsCard sx={{ gridArea: 'div2', height: '270px' }}>
          <CardTitle sx={{ marginBottom: '1.3rem' }}>Token Details</CardTitle>
          <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', mt: 4 }}>
            <FieldStyled
              id="tokenAddress"
              name="tokenAddress"
              label="Token Address"
              sx={{ minHeight: '90px' }}
              value={values.tokenAddress}
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              helperText={errors.tokenAddress}
              error={Boolean(errors.tokenAddress)}
            />
            <FieldStyled
              id="tokenPrice"
              name="tokenPrice"
              label="Token Price"
              sx={{ minHeight: '90px' }}
              value={values.tokenPrice}
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              helperText={errors.tokenPrice}
              error={Boolean(errors.tokenPrice)}
            />
          </Box>
        </ProjectDetailsCard>
        <ProjectDetailsCard sx={{ gridArea: 'div3', height: '270px' }}>
          <CardTitle sx={{ marginBottom: '1.3rem' }}>Network</CardTitle>
          <Box
            sx={{ display: 'flex', gap: 1.5, flexDirection: 'column', mt: 4 }}
          >
            <FieldStyled
              id="chain"
              name="chain"
              label="Chain"
              sx={{ minHeight: '90px' }}
              value={values.chain}
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              helperText={errors.chain}
              error={Boolean(errors.chain)}
            />
            <Box>
              <CardTitle sx={{ mb: 0 }}>Network Image</CardTitle>
              {values.networkImage ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                  }}
                >
                  <img
                    src={values.networkImage}
                    style={{
                      borderRadius: '6px',
                      marginInline: '0.5rem',
                    }}
                    width={44}
                    height={44}
                    alt=""
                  />
                  <CloseBtn
                    onClick={removeNetworkImage}
                    sx={{ padding: '0.1rem' }}
                  >
                    <CloseIcon sx={{ width: 15, height: 15 }} />
                  </CloseBtn>
                </Box>
              ) : (
                <Button
                  component="label"
                  htmlFor="networkImage"
                  sx={{
                    padding: '0',
                    marginInline: '0.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Box sx={{ ...flexCentered, flexDirection: 'column' }}>
                    <AddPhotoAlternateIcon sx={{ width: 44, height: 44 }} />
                    {errors.networkImage && (
                      <Typography
                        variant="h6"
                        color="error.main"
                        sx={{ fontSize: '12px !important', fontWeight: '400' }}
                      >
                        Required!
                      </Typography>
                    )}
                  </Box>
                  <input
                    accept="image/*"
                    hidden
                    id="networkImage"
                    multiple
                    type="file"
                    onChange={(event) => handleAddNetworkImage(event.target)}
                    onBlur={handleBlur}
                  />
                </Button>
              )}
            </Box>
          </Box>
        </ProjectDetailsCard>
        <ProjectDetailsCard sx={{ gridArea: 'div4', height: '270px' }}>
          <CardTitle sx={{ marginBottom: '1.3rem' }}>Availability</CardTitle>
          {/* <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant={values.hidden ? 'contained' : 'outlined'}
              onClick={() => handleIsHidden(true)}
            >
              Hidden
            </Button>
            <Button
              variant={values.hidden ? 'outlined' : 'contained'}
              onClick={() => handleIsHidden(false)}
            >
              Available
            </Button>
          </Box> */}
          <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
            <Button
              variant={
                values.status === ProjectStatus.NOT_LAUNCHED
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() => handleProjectStatus(ProjectStatus.NOT_LAUNCHED)}
            >
              Not Launched
            </Button>
            <Button
              variant={
                values.status === ProjectStatus.DISTRIBUTING
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() => handleProjectStatus(ProjectStatus.DISTRIBUTING)}
            >
              Distributing
            </Button>
            <Button
              variant={
                values.status === ProjectStatus.LIVE ? 'contained' : 'outlined'
              }
              onClick={() => handleProjectStatus(ProjectStatus.LIVE)}
            >
              Live
            </Button>
            <Button
              variant={
                values.status === ProjectStatus.LIVE_PRIVATE
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() => handleProjectStatus(ProjectStatus.LIVE_PRIVATE)}
            >
              Private
            </Button>
            <Button
              variant={
                values.status === ProjectStatus.REFUNDED
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() => handleProjectStatus(ProjectStatus.REFUNDED)}
            >
              Refunded
            </Button>
            <Button
              variant={
                values.status === ProjectStatus.COMPLETED
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() => handleProjectStatus(ProjectStatus.COMPLETED)}
            >
              Completed
            </Button>
          </Box>
        </ProjectDetailsCard>
        {(values.status === ProjectStatus.LIVE ||
          values.status === ProjectStatus.LIVE_PRIVATE) && (
          <ProjectDetailsCard sx={{ gridArea: 'div4', height: '316px' }}>
            <CardTitle sx={{ marginBottom: '1.3rem' }}>Investment</CardTitle>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <FieldStyled
                id="minInvestment"
                name="minInvestment"
                label="Min Investment"
                sx={{ minHeight: '90px' }}
                value={values.minInvestment}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                helperText={errors.minInvestment}
                error={Boolean(errors.minInvestment)}
              />
              <FieldStyled
                id="maxInvestment"
                name="maxInvestment"
                label="Max Investment"
                sx={{ minHeight: '90px' }}
                value={values.maxInvestment}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                helperText={errors.maxInvestment}
                error={Boolean(errors.maxInvestment)}
              />
              <FieldStyled
                id="fundraisingTarget"
                name="fundraisingTarget"
                label="Fundraising Target"
                sx={{ minHeight: '90px' }}
                value={values.fundraisingTarget}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                helperText={errors.fundraisingTarget}
                error={Boolean(errors.fundraisingTarget)}
              />
            </Box>
          </ProjectDetailsCard>
        )}
      </ProjectCreateDetails>
    </>
  );
}

export default ProjectEditValues;
