import { Box, Typography, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  BoxContainer,
  CardHeader,
  CardH6Text,
  ProgressDetailsBox,
  ProgressDetailsDivider,
  CurrentPriceBox,
} from '@styles/project/SProjectCardElem';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Formik,
  FormikProvider,
  useFormik,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import {
  calculate,
  calculateProfitLoss,
  calculateTotalValueAfterFee,
  cryptoFormat,
  formatNr,
} from '@utils/functions';

import useListings from '@hooks/useListings';
import {
  WTBListingInitialValues,
  WTIListingInitialValues,
  WTSListingInitialValues,
} from '@formik/modal/ModalInitialData';
import { ProjectStatus } from '@constants/CProject';
import {
  TCreateWTBListingInitialValues,
  TCreateWTIListingInitialValues,
  TCreateWTSListingInitialValues,
} from '@typescript/TModalFormik';
import WtbStep1Modal from '@components/modals/listings/WtbStep1Modal';
import WtbStep2Modal from '@components/modals/listings/WtbStep2Modal';
import WtsStep1Modal from '@components/modals/listings/WtsStep1Modal';
import WtsStep2Modal from '@components/modals/listings/WtsStep2Modal';
import WtiStep1Modal from '@components/modals/investment/WtiStep1Modal';
import { borderRadius6, flexColumn } from '@styles/shared/SCss';
import { Day } from '@typescript/models/Listing.model';
import {
  WTBListingValidationSchema,
  WTIListingValidationSchema,
  WTSListingValidationSchema,
} from '@formik/modal/ModalValidation';
import { BuyButton, ClaimButton, SellButton } from '@styles/shared/SButtons';
import { decryptWithSecret } from '@utils/encryptSlug';
import useUser from '@hooks/useUser';
import WtiErrorModal from '@components/modals/investment/WtiErrorModal';
import WtiStep2Modal from '@components/modals/investment/WtiStep2Modal';
import WtiSuccessModal from '@components/modals/investment/WtiSuccessModal';
import useTransfer from '@hooks/useTransfer';
import { BLOCK_CONFIRMATIONS } from '@constants/CLimits';
import TxSentModal from '@components/modals/transactions/TxSentModal';
import TxInvestmentConfirmationModal from '@components/modals/transactions/TxInvestmentConfirmationModal';
import { createListingZod } from '@typescript/dtos/listing/createListing.dto';
import { createTransactionInvestmentZod } from '@typescript/dtos/transactionInvestment/createTransactionInvestment.dto';
import { TProject } from '@typescript/models/Project.model';
import { useLazyCheckTxInvestmentQuery } from '@store/api/transactionInvestmentApi';
import { checkTxInvestmentZod } from '@typescript/dtos/transactionInvestment/getTxInvestment.dto';
import useNotification from '@hooks/useNotification';
import useActionOrConnect from '@hooks/useActionOrConnect';
import { coinZod, coinArrayZod } from '@constants/CCoin';
import ProjectStatusCom from '../helpers/ProjectStatusCom';

interface Props {
  project: TProject;
  // changeIsLoading: (state: boolean) => void;
}

function ProjectDealsCard({ project }: Props) {
  const handleActionOrConnect = useActionOrConnect();
  const theme = useTheme();

  const navigate = useNavigate();

  const { userLockedTokensOnProject } = project;

  const [searchParams] = useSearchParams();

  const {
    stableTransferFunds,
    createTransactionInvestment,
    confirmInvestmentTransaction,
  } = useTransfer();

  const { userCoins, hightestUserBalance } = useUser();

  const { currentUser, userInformation } = useUser();

  const { createWTS, createWTB } = useListings();

  const [checkTxInvestment] = useLazyCheckTxInvestmentQuery();

  const { notifySuccess, notifyError, notifyInfo } = useNotification();

  const { label } = ProjectStatusCom({ project });

  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  // urlparams initial values
  const [investInitialValues, setInvestInitialValues] =
    useState<TCreateWTIListingInitialValues>(WTIListingInitialValues);

  const [wtbInitialValues, setWtbInitialValues] =
    useState<TCreateWTBListingInitialValues>(WTBListingInitialValues);

  const [currentTransactionHash, setCurrentTransactionHash] =
    useState<string>('');

  const [step1WTBModal, setStep1WTBModal] = useState<boolean>(false);

  const [step2WTBModal, setStep2WTBModal] = useState<boolean>(false);

  const [modalWTBSteps, setWTBModalSteps] = useState<1 | 2>(1);

  const [step1WTIModal, setStep1WTIModal] = useState<boolean>(false);

  const [step2WTIModal, setStep2WTIModal] = useState<boolean>(false);

  const [step3WTIModal, setStep3WTIModal] = useState<boolean>(false);

  const [step4WTIModal, setStep4WTIModal] = useState<boolean>(false);

  const [successWTIModal, setSuccessWTIModal] = useState<boolean>(false);

  const [errorWTIModal, setErrorWTIModal] = useState<boolean>(false);

  const [modalWTISteps, setModalWTISteps] = useState<1 | 2 | 3 | 4 | 5 | 6>(1);

  const [step1WTSModal, setStep1WTSModal] = useState<boolean>(false);

  const [step2WTSModal, setStep2WTSModal] = useState<boolean>(false);

  const [modalWTSSteps, setWTSModalSteps] = useState<1 | 2>(1);

  const changeWTBModalSteps = (number: 1 | 2) => {
    setWTBModalSteps(number);
  };

  const changeWTIModalSteps = (number: 1 | 2 | 3 | 4 | 5) => {
    setModalWTISteps(number);
  };

  const changeWTSModalSteps = (number: 1 | 2) => {
    setWTSModalSteps(number);
  };

  const calculateInvestmentAmountRemaining = () => {
    const remainingInvestment = calculate(
      project.fundraisingTarget,
      '-',
      project.investmentAmount,
    );
    return remainingInvestment < 0 ? 0 : remainingInvestment;
  };

  const handleGetCorrectFee = (projectDeals: TProject) => {
    const customFee = Number(searchParams.get('fee'));
    const fee = { ...project.fee };

    if (searchParams.get('fee')) {
      if (customFee === fee.feeInPercentage) {
        return fee.feeInPercentage;
      }
      if (customFee === fee.feeInPercentagePrivate) {
        return fee.feeInPercentagePrivate;
      }
    }
    if (fee !== undefined) {
      return projectDeals.status === ProjectStatus.LIVE
        ? fee.feeInPercentage
        : fee.feeInPercentagePrivate;
    }
    return 0;
  };

  console.log();

  const handleOnSubmitFormikWTB = async (
    values: TCreateWTBListingInitialValues,
  ) => {
    try {
      // changeIsLoading(true);
      await createWTB(
        createListingZod.parse({
          price: +values.totalCost,
          tokenPrice: +values.tokenPrice,
          coins: values.coins,
          expireIn: values.expireIn as Day,
          amount: +values.tokenAmount,
          projectId: project.id,
          discordName: values.discordName,
          telegramLink: values.telegramLink,
        }),
      ).unwrap();
      notifySuccess('Listing created!');
      // changeIsLoading(false);
      setStep1WTBModal(false);
      setStep2WTBModal(false);
      setWTBModalSteps(1);
    } catch (error) {
      // changeIsLoading(false);
      setStep1WTBModal(false);
      setStep2WTBModal(false);
      setWTBModalSteps(1);
      notifyError('Listing creation failed!');
      throw error;
    }
  };

  const handleOnSubmitFormikWTS = async (
    values: TCreateWTSListingInitialValues,
  ) => {
    try {
      await createWTS(
        createListingZod.parse({
          amount: +values.tokenAmount,
          coins: values.coins,
          expireIn: +values.expireIn as Day,
          negotiable: values.negotiable,
          price: +values.totalCost,
          tokenPrice: +values.tokenPrice,
          projectId: project.id,
          discordName: values.discordName,
          telegramLink: values.telegramLink,
        }),
      ).unwrap();
      notifySuccess('Listing created!');
      setStep1WTSModal(false);
      setStep2WTSModal(false);
    } catch (error) {
      setStep1WTSModal(false);
      setStep2WTSModal(false);
      notifyError('Listing creation failed!');
      throw error;
    }
  };

  const handleOnSubmitFormikWTI = async (
    values: TCreateWTIListingInitialValues,
    projectDeals: TProject,
  ) => {
    const getFeeProject = { ...projectDeals.fee };
    if (getFeeProject && currentUser) {
      try {
        setModalWTISteps(3);
        setStep3WTIModal(true);
        await checkTxInvestment(
          checkTxInvestmentZod.parse({
            projectId: projectDeals.id,
            totalCost: +values.totalCost,
          }),
        ).unwrap();
        const txResponse = await stableTransferFunds({
          amount: +values.totalCost,
          coin: values.coin,
          receiverAddress: getFeeProject.lvWallet,
        });
        await createTransactionInvestment(
          createTransactionInvestmentZod.parse({
            feeInPercentage: handleGetCorrectFee(project),
            project: project.id,
            totalTokens: +values.tokenAmount,
            investmentAmount: +values.totalCost,
            transactionHash: txResponse.hash,
            coin: values.coin,
            investmentAmountWithFee: calculateTotalValueAfterFee(
              handleGetCorrectFee(project),
              +values.totalCost,
            ),
            acceptTerms: values.acceptTerms,
          }),
        ).unwrap();
        setModalWTISteps(4);
        setStep4WTIModal(true);
        setCurrentTransactionHash(txResponse.hash);

        await txResponse.wait(BLOCK_CONFIRMATIONS);
        await confirmInvestmentTransaction(txResponse.hash).unwrap();

        notifyInfo('Transaction confirmed.');
        setModalWTISteps(5);
        setSuccessWTIModal(true);
      } catch (error) {
        setModalWTISteps(6);
        setErrorWTIModal(true);
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: investInitialValues,
    validationSchema: WTIListingValidationSchema(
      project.maxInvestment,
      project.minInvestment,
      hightestUserBalance(),
      calculateInvestmentAmountRemaining(),
    ),
    onSubmit: (values) => handleOnSubmitFormikWTI(values, project),
  });

  const handleNotLaunchedBuy = () => {
    setStep1WTBModal(true);
    changeWTBModalSteps(1);
  };

  const handleNotLaunchedSell = () => {
    setStep1WTSModal(true);
    changeWTSModalSteps(1);
  };
  const handleDistributingBuy = () => {
    setStep1WTBModal(true);
    changeWTBModalSteps(1);
  };
  const handleDistributingSell = () => {
    setStep1WTSModal(true);
    changeWTSModalSteps(1);
  };

  const handleLiveInvest = () => {
    setStep1WTIModal(true);
    changeWTIModalSteps(1);
  };

  const { resetForm } = formik;

  const displayTradeActions = (status: ProjectStatus) => {
    switch (status) {
      case ProjectStatus.NOT_LAUNCHED:
        return (
          <>
            <BuyButton
              fullWidth
              variant="text"
              onClick={() => {
                handleActionOrConnect(handleNotLaunchedBuy);
              }}
              disableRipple
            >
              Buy
            </BuyButton>
            <SellButton
              fullWidth
              onClick={() => {
                handleActionOrConnect(handleNotLaunchedSell);
              }}
              disabled={currentUser ? userLockedTokensOnProject <= 0 : false}
              disableRipple
            >
              Sell
            </SellButton>
          </>
        );
      case ProjectStatus.DISTRIBUTING:
        return (
          <>
            <BuyButton
              fullWidth
              variant="text"
              onClick={() => {
                handleActionOrConnect(handleDistributingBuy);
              }}
              disableRipple
            >
              Buy
            </BuyButton>
            <SellButton
              fullWidth
              onClick={() => {
                handleActionOrConnect(handleDistributingSell);
              }}
              disabled={currentUser ? userLockedTokensOnProject <= 0 : false}
              disableRipple
            >
              Sell
            </SellButton>
            <ClaimButton
              fullWidth
              // TODO: Enable this feature for production
              disabled
              disableRipple
            >
              Claim
            </ClaimButton>
          </>
        );
      case ProjectStatus.COMPLETED:
        return (
          <ClaimButton
            fullWidth
            // TODO: Enable this feature for production
            disabled
            disableRipple
          >
            Claim
          </ClaimButton>
        );
      case ProjectStatus.LIVE:
        return (
          <BuyButton
            fullWidth
            variant="text"
            onClick={() => {
              handleActionOrConnect(handleLiveInvest);
            }}
            disableRipple
          >
            Invest
          </BuyButton>
        );
      default:
        return null;
    }
  };

  const projectParamsActions = () => {
    const actionType = searchParams.get('action');
    const projectName = searchParams.get('project');
    const tokenAmount = searchParams.get('amount');
    const acceptTerms = Boolean(searchParams.get('acceptTerms'));
    switch (actionType) {
      case 'invest':
        {
          const coinParam = searchParams.get('coin');
          if (
            projectName &&
            projectName.toLowerCase() === project.name.toLowerCase() &&
            tokenAmount &&
            coinParam &&
            acceptTerms
          ) {
            const coin = coinZod.parse(coinParam);
            const totalCost = formatNr(+tokenAmount * project.tokenPrice);
            setAcceptedTerms(acceptTerms);
            setInvestInitialValues({
              totalCost,
              tokenAmount,
              coin,
              acceptTerms,
            });
            setStep2WTIModal(true);
            setModalWTISteps(2);
          }
        }
        break;
      case 'buy':
        {
          const coinsParam = searchParams.get('coins')?.split(',');
          const tokenPrice = searchParams.get('tokenPrice');
          const expireIn = Number(searchParams.get('expireIn'));
          const telegramParam = Boolean(searchParams.get('telegram'));
          const discordParam = Boolean(searchParams.get('discord'));

          if (
            projectName &&
            projectName.toLowerCase() === project.name.toLowerCase() &&
            tokenAmount &&
            coinsParam &&
            acceptTerms &&
            tokenPrice &&
            expireIn &&
            telegramParam &&
            discordParam
          ) {
            const coins = coinArrayZod.parse(coinsParam);
            const totalCost = formatNr(+tokenAmount * +tokenPrice);
            let telegramLink = '';
            if (telegramParam) {
              telegramLink = userInformation?.telegramLink || '';
            }
            let discordName = '';
            if (discordParam) {
              discordName = userInformation?.discordName || '';
            }

            setAcceptedTerms(acceptTerms);

            setWtbInitialValues({
              tokenAmount,
              coins,
              expireIn,
              acceptTerms,
              totalCost,
              tokenPrice,
              telegramLink,
              discordName,
            });
            setWTBModalSteps(2);
            setStep2WTBModal(true);
          }
        }
        break;
      default:
        return null;
    }
    return null;
  };

  useEffect(() => {
    projectParamsActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const idProject = searchParams.get('id');

    if (idProject) {
      const decryptId = decryptWithSecret(idProject);
      if (project.id === decryptId) {
        if (!currentUser) {
          handleActionOrConnect();
        } else {
          setStep1WTIModal(true);
          changeWTIModalSteps(1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, project, currentUser]);

  return (
    <>
      <BoxContainer>
        <CardHeader onClick={() => navigate(project.slug)}>
          <Box sx={{ display: 'flex' }} mr={1}>
            <img
              src={project.image}
              width={40}
              height={40}
              alt=""
              style={borderRadius6}
            />
            <Box marginLeft="5px">
              <Typography>{project.name}</Typography>
              <Typography
                variant="h3"
                sx={{ color: theme.palette.text.secondary, fontSize: 12 }}
              >
                {project.symbol}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              backgroundColor: 'transparent',
              textTransform: 'capitalize',
            }}
          >
            {label}
          </Box>
        </CardHeader>

        <ProgressDetailsBox>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              component="span"
              fontSize={13}
              fontWeight={700}
              mr="4px"
              lineHeight="initial"
            >
              {project.countWTB}
            </Typography>
            <CardH6Text>Buyers</CardH6Text>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              component="span"
              fontSize={13}
              fontWeight={700}
              mr="4px"
              lineHeight="initial"
            >
              {project.countWTS}
            </Typography>
            <CardH6Text>Sellers</CardH6Text>
          </Box>

          <ProgressDetailsDivider />

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              component="span"
              fontSize={13}
              fontWeight={700}
              mr="4px"
              lineHeight="initial"
            >
              0
            </Typography>
            <CardH6Text>to Claim</CardH6Text>
          </Box>
        </ProgressDetailsBox>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            padding: '10px',
          }}
        >
          <Box sx={{ ...flexColumn, marginRight: '15px' }}>
            <Box
              sx={{
                display: 'flex',
                paddingBottom: '8px',
                alignItems: 'center',
              }}
            >
              <CardH6Text>Price</CardH6Text>
              <Tooltip title={+project.tokenPrice} placement="right-end">
                <CurrentPriceBox>
                  {cryptoFormat(project.tokenPrice, {
                    fontSize: '12px !important',
                  })}
                </CurrentPriceBox>
              </Tooltip>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CardH6Text>Type</CardH6Text>

              <Typography variant="h6">{project.round}</Typography>
            </Box>
          </Box>

          <Box sx={{ ...flexColumn }}>
            <Box
              sx={{
                display: 'flex',
                paddingBottom: '8px',
                alignItems: 'center',
              }}
            >
              <CardH6Text>Current Price</CardH6Text>

              <Tooltip title={project.currentPrice} placement="right-end">
                <CurrentPriceBox>
                  {cryptoFormat(project.currentPrice, {
                    fontSize: '12px !important',
                  })}
                </CurrentPriceBox>
              </Tooltip>

              <Box
                sx={{
                  marginLeft: '4px',
                  '& p': {
                    fontSize: '12px',
                  },
                }}
              >
                {calculateProfitLoss(+project.tokenPrice, project.currentPrice)}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CardH6Text>Network</CardH6Text>

              <img
                src={project.networkImage}
                style={{
                  marginRight: '0.3rem',
                  borderRadius: '2px',
                }}
                width={16}
                height={16}
                alt=""
              />

              <Typography variant="h6">{project.chain}</Typography>
            </Box>
          </Box>
        </Box>
        {displayTradeActions(project.status) && (
          <Box
            sx={{
              display: 'flex',
              borderTop: `1px solid ${theme.palette.secondary.light}`,
            }}
          >
            {displayTradeActions(project.status)}
          </Box>
        )}
      </BoxContainer>
      <FormikProvider value={formik}>
        <>
          {modalWTISteps === 1 && step1WTIModal && (
            <WtiStep1Modal
              step1Modal={step1WTIModal}
              closeStep1Modal={() => {
                setStep1WTIModal(false);
              }}
              changeModalSteps={changeWTIModalSteps}
              project={project}
              openWtbStep2Modal={() => setStep2WTIModal(true)}
              userCoins={userCoins}
              feeInPercentage={handleGetCorrectFee(project)}
            />
          )}

          {modalWTISteps === 2 && step2WTIModal && (
            <WtiStep2Modal
              step2Modal={step2WTIModal}
              closeStep2Modal={() => setStep2WTIModal(false)}
              openWtiStep1Modal={() => setStep1WTIModal(true)}
              changeSteps={changeWTIModalSteps}
              project={project}
              feeInPercentage={handleGetCorrectFee(project)}
              acceptedTerms={acceptedTerms}
            />
          )}

          {modalWTISteps === 3 && step3WTIModal && (
            <TxInvestmentConfirmationModal
              onClose={() => {
                setStep3WTIModal(false);
                resetForm();
              }}
              open={step3WTIModal}
              tokenPrice={project.currentPrice}
            />
          )}

          {modalWTISteps === 4 && step4WTIModal && (
            <TxSentModal
              open={step4WTIModal}
              onClose={() => {
                setStep4WTIModal(false);
                resetForm();
              }}
              txHash={currentTransactionHash}
            />
          )}

          {modalWTISteps === 5 && successWTIModal && (
            <WtiSuccessModal
              successModal={successWTIModal}
              closeSuccessModal={() => {
                setSuccessWTIModal(false);
                setCurrentTransactionHash('');
              }}
              project={project}
              currentTransactionHash={currentTransactionHash}
            />
          )}

          {modalWTISteps === 6 && errorWTIModal && (
            <WtiErrorModal
              errorModal={errorWTIModal}
              closeErrorModal={() => setErrorWTIModal(false)}
            />
          )}
        </>
      </FormikProvider>
      <Formik
        enableReinitialize
        initialValues={wtbInitialValues}
        validationSchema={WTBListingValidationSchema(
          project.projectMaxLockedTokens,
          hightestUserBalance(),
        )}
        onSubmit={(values) => handleOnSubmitFormikWTB(values)}
      >
        <>
          {modalWTBSteps === 1 && step1WTBModal && (
            <WtbStep1Modal
              step1Modal={step1WTBModal}
              closeStep1Modal={() => setStep1WTBModal(false)}
              changeModalSteps={changeWTBModalSteps}
              hightestLockedTokens={project.projectMaxLockedTokens}
              project={project}
              openWtbStep2Modal={() => setStep2WTBModal(true)}
              userCoins={userCoins}
            />
          )}

          {modalWTBSteps === 2 && step2WTBModal && (
            <WtbStep2Modal
              step2Modal={step2WTBModal}
              closeStep2Modal={() => setStep2WTBModal(false)}
              openWtbStep1Modal={() => setStep1WTBModal(true)}
              changeSteps={changeWTBModalSteps}
              project={project}
              acceptedTerms={acceptedTerms}
            />
          )}
        </>
      </Formik>
      {userLockedTokensOnProject > 0 && (
        <Formik
          initialValues={WTSListingInitialValues}
          validate={(values) => {
            try {
              validateYupSchema(
                values,
                WTSListingValidationSchema(
                  project.currentPrice,
                  userLockedTokensOnProject,
                ),
                true,
                values,
              );
            } catch (err) {
              return yupToFormErrors(err);
            }

            return {};
          }}
          onSubmit={(values) => handleOnSubmitFormikWTS(values)}
        >
          <>
            {modalWTSSteps === 1 &&
              step1WTSModal &&
              userLockedTokensOnProject > 0 && (
                <WtsStep1Modal
                  openModal={step1WTSModal}
                  closeModal={() => setStep1WTSModal(false)}
                  changeSteps={changeWTSModalSteps}
                  openWtsStep2Modal={() => setStep2WTSModal(true)}
                  project={project}
                />
              )}

            {modalWTSSteps === 2 &&
              step2WTSModal &&
              userLockedTokensOnProject > 0 && (
                <WtsStep2Modal
                  openModal={step2WTSModal}
                  closeModal={() => setStep2WTSModal(false)}
                  changeSteps={changeWTSModalSteps}
                  openWtsStep1Modal={() => setStep1WTSModal(true)}
                  project={project}
                />
              )}
          </>
        </Formik>
      )}
    </>
  );
}

export default ProjectDealsCard;
