import useStyles from '@hooks/useStyles';
import { Box, Typography, useTheme } from '@mui/material';
import { TMyTotalAllocationsValues } from '@typescript/models/Allocation.model';
import { formatNr } from '@utils/functions';

interface Props {
  myTotalAllocationsValues: TMyTotalAllocationsValues;
}

function SummaryHeader({ myTotalAllocationsValues }: Props) {
  const { border1pxSecondaryLight } = useStyles();

  const theme = useTheme();

  const colStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    paddingBlock: '2rem',
  };

  const shadedWhiteColor = theme.palette.text.secondary;

  return (
    <Box
      className="SummaryHeader"
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderBottom: border1pxSecondaryLight,
        position: 'sticky',
        top: {
          xs: '66px',
          lg: '56px',
        },
        left: 0,
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          backdropFilter: 'blur(25px)',
          maxWidth: '1200px',
          margin: '0 auto',
          display: { xs: 'grid', md: 'flex' },
          justifyContent: 'space-between',
          padding: { sx: '0', md: '2rem 1rem' },

          gridTemplateColumns: ' 1fr 1fr',
        }}
      >
        <Box
          sx={{
            ...colStyle,

            padding: { xs: '14px', md: '0' },
            borderRight: { xs: border1pxSecondaryLight, md: 'none' },
            borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontSize: '12px',
              color: shadedWhiteColor,
            }}
          >
            Total Investment
          </Typography>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '17px', md: '24px' } }}
          >
            {formatNr(myTotalAllocationsValues.totalInvestment, true, 0)}
          </Typography>
        </Box>
        <Box
          sx={{
            ...colStyle,

            padding: { xs: '14px', md: '0' },
            borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
          }}
        >
          <Typography
            variant="h6"
            sx={{ mb: 1, fontSize: '12px', color: shadedWhiteColor }}
          >
            Assets Worth
          </Typography>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '17px', md: '24px' } }}
          >
            {formatNr(myTotalAllocationsValues.totalAssetWorth, true, 0)}
          </Typography>
        </Box>
        <Box
          sx={{
            ...colStyle,

            padding: { xs: '14px', md: '0' },
            borderRight: { xs: border1pxSecondaryLight, md: 'none' },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontSize: '12px',
              color: shadedWhiteColor,
            }}
          >
            Unlocked Value
          </Typography>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '17px', md: '24px' } }}
          >
            {formatNr(myTotalAllocationsValues.totalUnlockedValue, true, 0)}
          </Typography>
        </Box>
        <Box
          sx={{
            ...colStyle,

            padding: { xs: '14px', md: '0' },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontSize: '12px',
              color: shadedWhiteColor,
            }}
          >
            Locked Value
          </Typography>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '17px', md: '24px' } }}
          >
            {formatNr(myTotalAllocationsValues.totalLockedValue, true, 0)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
export default SummaryHeader;
