import { TAllocation } from '@typescript/models/Allocation.model';
import { Box, CircularProgress } from '@mui/material';
import DefaultPage from '@pages/Default';
import { CardsContainer } from '@styles/portfolio/SPortfolio';
import PortfolioCard from '@components/portfolio/PortfolioCard';
import { BuyGradientDark } from '@styles/shared/SButtons';

interface Props {
  myAllocations: TAllocation[];
  onOpenModal: (allocation: TAllocation) => void;
  loadMore: () => void;
  count: number;
  isLoading: boolean;
}
function CardsView({
  myAllocations,
  onOpenModal,
  loadMore,
  count,
  isLoading,
}: Props) {
  return (
    <Box sx={{ mx: 2 }}>
      {myAllocations.length > 0 ? (
        <CardsContainer>
          {myAllocations.map((allocation) => (
            <PortfolioCard
              key={allocation.id}
              onOpenModal={onOpenModal}
              allocation={allocation}
            />
          ))}
        </CardsContainer>
      ) : (
        <DefaultPage text="There are no projects to display at this moment" />
      )}
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: count === myAllocations.length ? 'none' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <BuyGradientDark onClick={loadMore}>See More</BuyGradientDark>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default CardsView;
