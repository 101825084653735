import WtiErrorModal from '@components/modals/investment/WtiErrorModal';
import WtiStep1Modal from '@components/modals/investment/WtiStep1Modal';
import WtiStep2Modal from '@components/modals/investment/WtiStep2Modal';
import WtiSuccessModal from '@components/modals/investment/WtiSuccessModal';
import EditIconCom from '@components/shared/EditIconCom';
import { ProjectStatus } from '@constants/CProject';
import { WTIListingInitialValues } from '@formik/modal/ModalInitialData';
import { WTIListingValidationSchema } from '@formik/modal/ModalValidation';
import useExcelDistribution from '@hooks/useExcelDistribution';
import useExcelProjectData from '@hooks/useExcelProjectData';
import useTokenDistribution from '@hooks/useTokenDistribution';
import useTransfer from '@hooks/useTransfer';
import useUser from '@hooks/useUser';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import {
  HeaderProject,
  ProjectTitle,
  SecondProjectTitle,
} from '@styles/project/SProjectSinglePage';
import { BuyGradientDark } from '@styles/shared/SButtons';
import { TCreateWTIListingInitialValues } from '@typescript/TModalFormik';
import {
  toRem,
  calculate,
  calculateTotalValueAfterFee,
} from '@utils/functions';
import { encryptWithSecret } from '@utils/encryptSlug';
import { Svgs } from '@utils/svgs';
import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TxSentModal from '@components/modals/transactions/TxSentModal';
import TxInvestmentConfirmationModal from '@components/modals/transactions/TxInvestmentConfirmationModal';
import { useLazyGetActiveAllocationQuery } from '@store/api/allocationApi';
import { createTransactionInvestmentZod } from '@typescript/dtos/transactionInvestment/createTransactionInvestment.dto';
import { TProject, TEditSection } from '@typescript/models/Project.model';
import { checkTxInvestmentZod } from '@typescript/dtos/transactionInvestment/getTxInvestment.dto';
import { useLazyCheckTxInvestmentQuery } from '@store/api/transactionInvestmentApi';
import useNotification from '@hooks/useNotification';
import { BLOCK_CONFIRMATIONS } from '@constants/CLimits';
import useActionOrConnect from '@hooks/useActionOrConnect';

interface Props {
  project: TProject;
  changeEditSection: (value: boolean, section: keyof TEditSection) => void;
}
function ProjectHeader({ project, changeEditSection }: Props) {
  const [checkTxInvestment] = useLazyCheckTxInvestmentQuery();

  const [step1WTIModal, setStep1WTIModal] = useState<boolean>(false);

  const [step2WTIModal, setStep2WTIModal] = useState<boolean>(false);

  const [step3WTIModal, setStep3WTIModal] = useState<boolean>(false);

  const [successWTIModal, setSuccessWTIModal] = useState<boolean>(false);

  const [errorWTIModal, setErrorWTIModal] = useState<boolean>(false);

  const [step4WTIModal, setStep4WTIModal] = useState<boolean>(false);

  const [modalWTISteps, setModalWTISteps] = useState<1 | 2 | 3 | 4 | 5 | 6>(1);

  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const [currentTransactionHash, setCurrentTransactionHash] =
    useState<string>('');

  const { handleExportDistributions } = useExcelDistribution();

  const {
    stableTransferFunds,
    createTransactionInvestment,
    confirmInvestmentTransaction,
  } = useTransfer();

  const navigate = useNavigate();
  const handleActionOrConnect = useActionOrConnect();

  const { handleExportProjects } = useExcelProjectData();

  const { userCoins, hightestUserBalance } = useUser();

  const { currentUser } = useUser();

  const { notifyError, notifySuccess, notifyInfo } = useNotification();

  const [allAllocations] = useLazyGetActiveAllocationQuery();

  const { tokenDistributions } = useTokenDistribution();

  const { isAdmin } = useUser();

  const [isCopied, setIsCopied] = useState(false);

  const [distributionsExist, setDistributionsExist] = useState(false);

  const urlPrivate = `${window.location.origin}/projects?id=${encryptWithSecret(
    project.id,
  )}&fee=${project.fee.feeInPercentagePrivate}`;

  const url = `${window.location.origin}/projects?id=${encryptWithSecret(
    project.id,
  )}&fee=${project.fee.feeInPercentage}`;

  const changeWTIModalSteps = (number: 1 | 2 | 3 | 4 | 5) => {
    setModalWTISteps(number);
  };

  const calculateInvestmentAmountRemaining = () => {
    const remainingInvestment = calculate(
      project.fundraisingTarget,
      '-',
      project.investmentAmount,
    );
    return remainingInvestment < 0 ? 0 : remainingInvestment;
  };

  const handleGetCorrectFee = (projectDeals: TProject) => {
    const fee = { ...projectDeals.fee };
    if (fee !== undefined) {
      return projectDeals.status === ProjectStatus.LIVE
        ? fee.feeInPercentage
        : fee.feeInPercentagePrivate;
    }
    return 0;
  };

  const handleExportProjectBtn = async () => {
    setExportLoading(true);
    const allocations = await allAllocations({
      page: 1,
      // TODO: migrated on backend
      pageSize: 3000,
    }).unwrap();
    if (!allocations) {
      throw Error('allAllocations can not be found');
    }
    handleExportProjects(allocations, [project]);
    setExportLoading(false);
  };

  const handleExportDistributionsBtn = async () => {
    const distributions = await tokenDistributions({
      page: 1,
      pageSize: 200,
    }).unwrap();
    handleExportDistributions(distributions, [project]);
  };

  const copyPrivateLink = async (type: string) => {
    try {
      await navigator.clipboard.writeText(
        type === 'private' ? urlPrivate : url,
      );
      notifySuccess('The link has been copied!');
      setIsCopied(true);
    } catch (err) {
      notifyError('Something went wrong!');
    }
  };

  const handleOnSubmitFormikWTI = async (
    values: TCreateWTIListingInitialValues,
    projectDeals: TProject,
  ) => {
    const getFeeProject = { ...projectDeals.fee };

    if (getFeeProject && currentUser) {
      try {
        setModalWTISteps(3);
        setStep3WTIModal(true);
        await checkTxInvestment(
          checkTxInvestmentZod.parse({
            projectId: projectDeals.id,
            totalCost: +values.totalCost,
          }),
        ).unwrap();
        const txResponse = await stableTransferFunds({
          amount: +values.totalCost,
          coin: values.coin,
          receiverAddress: getFeeProject.lvWallet,
        });
        await createTransactionInvestment(
          createTransactionInvestmentZod.parse({
            feeInPercentage: handleGetCorrectFee(project),
            project: project.id,
            totalTokens: +values.tokenAmount,
            investmentAmount: +values.totalCost,
            transactionHash: txResponse.hash,
            coin: values.coin,
            investmentAmountWithFee: calculateTotalValueAfterFee(
              handleGetCorrectFee(project),
              +values.totalCost,
            ),
            acceptTerms: values.acceptTerms,
          }),
        ).unwrap();

        setModalWTISteps(4);
        setStep4WTIModal(true);
        setCurrentTransactionHash(txResponse.hash);

        await txResponse.wait(BLOCK_CONFIRMATIONS);
        await confirmInvestmentTransaction(txResponse.hash).unwrap();
        notifyInfo('Transaction confirmed.');

        setModalWTISteps(5);
        setSuccessWTIModal(true);
      } catch (error) {
        setModalWTISteps(6);
        setErrorWTIModal(true);
      }
    }
  };

  const formik = useFormik({
    initialValues: WTIListingInitialValues,
    validationSchema: WTIListingValidationSchema(
      project.maxInvestment,
      project.minInvestment,
      hightestUserBalance(),
      calculateInvestmentAmountRemaining(),
    ),
    onSubmit: (values) => handleOnSubmitFormikWTI(values, project),
  });

  const { resetForm } = formik;

  const handleInvest = () => {
    setStep1WTIModal(true);
    changeWTIModalSteps(1);
  };

  useEffect(() => {
    const checkDistributionsExists = async () => {
      const distributions = await tokenDistributions({
        page: 1,
        pageSize: 200,
      }).unwrap();

      const exist = distributions.some(
        (distribution) => distribution.project.id === project.id,
      );

      setDistributionsExist(exist);
    };
    if (currentUser) {
      checkDistributionsExists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id]);

  return (
    <HeaderProject
      sx={{
        alignItems: { xs: 'unset', md: 'center' },
        position: 'relative',
        flexDirection: { xs: 'column', md: 'row' },
        gap: '20px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => navigate(-1)}>
          <Svgs.BigLeftArrow
            style={{ padding: '0.2rem', width: 24, height: 24 }}
          />
        </IconButton>
        <img
          src={project?.image}
          style={{ borderRadius: '4px', marginLeft: '1rem' }}
          width={36}
          height={36}
          alt=""
        />
        <ProjectTitle>{project.name}</ProjectTitle>
        <SecondProjectTitle>{project.symbol}</SecondProjectTitle>
      </Box>
      <Box sx={{ display: 'flex', marginLeft: { xs: 0, md: 'auto' } }}>
        {project.status === ProjectStatus.LIVE && (
          <BuyGradientDark
            fullWidth
            sx={{ mr: '8px' }}
            onClick={() => handleActionOrConnect(handleInvest)}
            disableRipple
          >
            Invest
          </BuyGradientDark>
        )}
        {isAdmin && (
          <>
            {(project.status === ProjectStatus.LIVE_PRIVATE ||
              project.status === ProjectStatus.LIVE) && (
              <Tooltip
                title={isCopied ? 'Copied!' : 'Copy'}
                arrow
                placement="top"
              >
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={() => copyPrivateLink('public')}
                >
                  Project Link Fee {project.fee.feeInPercentage}
                </Button>
              </Tooltip>
            )}
            {(project.status === ProjectStatus.LIVE_PRIVATE ||
              project.status === ProjectStatus.LIVE) && (
              <Tooltip
                title={isCopied ? 'Copied!' : 'Copy'}
                arrow
                placement="top"
              >
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={() => copyPrivateLink('private')}
                >
                  Project Link Fee {project.fee.feeInPercentagePrivate}
                </Button>
              </Tooltip>
            )}
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              onClick={handleExportProjectBtn}
              disabled={exportLoading}
            >
              {exportLoading && (
                <CircularProgress size={20} sx={{ marginRight: toRem(8) }} />
              )}
              Export Project
            </Button>
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              onClick={handleExportDistributionsBtn}
              disabled={!distributionsExist}
            >
              Export Distributions
            </Button>
            <EditIconCom
              openEditSection={() => changeEditSection(true, 'header')}
            />
          </>
        )}
      </Box>
      <FormikProvider value={formik}>
        <>
          {modalWTISteps === 1 && step1WTIModal && (
            <WtiStep1Modal
              step1Modal={step1WTIModal}
              closeStep1Modal={() => {
                setStep1WTIModal(false);
                navigate(`/projects/${project.slug}`);
              }}
              changeModalSteps={changeWTIModalSteps}
              project={project}
              openWtbStep2Modal={() => setStep2WTIModal(true)}
              userCoins={userCoins}
              feeInPercentage={handleGetCorrectFee(project)}
            />
          )}

          {modalWTISteps === 2 && step2WTIModal && (
            <WtiStep2Modal
              step2Modal={step2WTIModal}
              closeStep2Modal={() => setStep2WTIModal(false)}
              openWtiStep1Modal={() => setStep1WTIModal(true)}
              changeSteps={changeWTIModalSteps}
              project={project}
              feeInPercentage={handleGetCorrectFee(project)}
              acceptedTerms={false}
            />
          )}

          {modalWTISteps === 3 && step3WTIModal && (
            <TxInvestmentConfirmationModal
              onClose={() => {
                setStep3WTIModal(false);
                resetForm();
              }}
              open={step3WTIModal}
              tokenPrice={project.currentPrice}
            />
          )}

          {modalWTISteps === 4 && step4WTIModal && (
            <TxSentModal
              open={step4WTIModal}
              onClose={() => {
                setStep4WTIModal(false);
                resetForm();
              }}
              txHash={currentTransactionHash}
            />
          )}

          {modalWTISteps === 5 && successWTIModal && (
            <WtiSuccessModal
              successModal={successWTIModal}
              closeSuccessModal={() => {
                setSuccessWTIModal(false);
                setCurrentTransactionHash('');
              }}
              project={project}
              currentTransactionHash={currentTransactionHash}
            />
          )}

          {modalWTISteps === 6 && errorWTIModal && (
            <WtiErrorModal
              errorModal={errorWTIModal}
              closeErrorModal={() => setErrorWTIModal(false)}
            />
          )}
        </>
      </FormikProvider>
    </HeaderProject>
  );
}

export default ProjectHeader;
